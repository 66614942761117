import React from 'react'
import LogoImg from '../images/GeoVisually.png'
import styled from '@emotion/styled'
// import { useMousePosition } from '../util/useMousePosition'

const Img = styled.img(({ r1, r2 }) => `
  width: 100%;
  max-width: 590px;
  background: rgba(255,255,255,0.3);
  padding: 3vh 2vw 1vh 2vw;
  box-sizing: border-box;
  // border-radius: 15%;
  border-radius: 2%;
  box-shadow: 0px 0px 20px white;
  filter: invert(100%);
  ${
  r1 != null && r2 != null 
    ? `transform: rotate3d(0, 1, 0, ${r1}deg) rotate3d(0, 1, 1, ${r2}deg);`
    : ''
  }
`)

const Logo3d = () => {
  // const { x, y } = useMousePosition();
  // const w = window.innerWidth
  // const h = window.innerHeight
  // const r1 = x != null ? 90 + 120*(x/(w/2) - 1) : null
  // const r2 = y != null ? 40*(y/(h/2) - 1) : null
  const r1 = undefined; const r2 = undefined;
  return (
    <Img
      r1={r1}
      r2={r2}
      src={LogoImg} alt="GeoVisually"
    />
  )
}

export default Logo3d
